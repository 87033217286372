import React from "react";
import lillogo from "../../images/lillogo.svg";
import "./ModalInfo.css";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

function ModalInfo() {
  const { t } = useTranslation();

  const [modalOn, setModalOn] = useState(true);

  let modalInfoRef = useRef();

  useEffect(() => {
    const modalShown = sessionStorage.getItem("salesInfoShown");

    if (modalShown) {
      setModalOn(false);
    }

    let handler = (e) => {
      if (!modalInfoRef.current.contains(e.target)) {
        setModalOn(false);

        sessionStorage.setItem("salesInfoShown", "true");
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const handleContinueClick = () => {
    setModalOn(false);

    sessionStorage.setItem("salesInfoShown", "true");
  };

  return (
    <div
      style={{ display: modalOn ? "grid" : "none" }}
      className="modal-popup"
      ref={modalInfoRef}
    >
      {/* <span className="ua-flag"></span> */}
      <button className="close-modal-popup" onClick={handleContinueClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="24"
          viewBox="0 0 30 24"
          fill="none"
        >
          <g clipPath="url(#clip0_920_16981)">
            <path
              d="M5.03906 21.6663L25.0391 0.833008M5.03906 0.833008L25.0391 21.6663"
              stroke="#50BDFF"
              strokeWidth="2.34375"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_920_16981">
              <rect width="30" height="23.3333" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
      <img src={lillogo} alt="Ї" />
      <h2>{t("ModalInfo.Meet")}</h2>
      <p>
        {t("ModalInfo.Try")} 
        <br />
        {t("ModalInfo.Try2")} 
        <br />
        {t("ModalInfo.Try3")} 
        <br />
        <br />
        {t("ModalInfo.Delivery")} 
      </p>
      <button className="modal-popup-continue" onClick={handleContinueClick}>
        {t("ModalInfo.Continue")}
      </button>
    </div>
  );
}

export default ModalInfo;
